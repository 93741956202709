import { Alert } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { messages } from "../../locales/messages/subscription";
import { Link } from "../Link";

export function PaymentOverdueBanner() {
  return (
    <Alert
      severity="error"
      sx={{ width: "100%", alignSelf: "center", mr: 2 }}
      variant="filled"
    >
      <FormattedMessage {...messages.notif.overdue} />{" "}
      <Link href="/account" color="inherit">
        <FormattedMessage {...messages.buttons.manageSubscription} />
      </Link>
    </Alert>
  );
}
