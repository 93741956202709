import { Theme, Components } from "@mui/material/styles";

export const IconButton: Components<Theme> = {
  MuiIconButton: {
    variants: [
      {
        props: { color: "default" },
        style: ({ theme }) => ({
          "&:hover": { backgroundColor: theme.palette.action.hover },
        }),
      },
      {
        props: { color: "inherit" },
        style: ({ theme }) => ({
          "&:hover": { backgroundColor: theme.palette.action.hover },
        }),
      },
    ],
    styleOverrides: {
      root: {},
    },
  },
};

export default IconButton;
