import PropTypes from "prop-types";
import { PropsWithChildren } from "react";
import { CssBaseline } from "@mui/material";
import { ThemeProvider, createTheme, ThemeOptions } from "@mui/material/styles";
import { plPL } from "@mui/material/locale";

import palette from "./palette";
import typography from "./typography";
import componentsOverride from "./overrides";
import shadows, { customShadows } from "./shadows";

ThemeConfig.propTypes = {
  children: PropTypes.node,
};

const themeOptions: ThemeOptions = {
  palette,
  shape: { borderRadius: 8 },
  typography,
  shadows,
  customShadows,
  components: componentsOverride() as ThemeOptions["components"],
};
export const theme = createTheme(themeOptions, plPL);

export default function ThemeConfig({ children }: PropsWithChildren<{}>) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
