import { defineMessage } from "react-intl";

export const messages = {
  ui: {
    plan: defineMessage({ defaultMessage: "Plan", id: "fz0z4c" }),
    slotsLimit: defineMessage({
      defaultMessage: "Limit użytkowników: {limit}",
      id: "lnZSIY",
    }),
    nextPaymentDate: defineMessage({
      defaultMessage: "Data następnej płatności",
      id: "j+L1sb",
    }),
    validUntilDate: defineMessage({
      defaultMessage: "Subskrypcja aktywna do",
      id: "CTjess",
    }),
  },

  notif: {
    inactive: defineMessage({
      defaultMessage: "Konto jest nieaktywne",
      id: "0Bonyc",
    }),
    trial: defineMessage({
      defaultMessage:
        "Używasz obecnie wersji testowej. Konto pozostanie aktywne do",
      id: "5ECUwq",
    }),
    overdue: defineMessage({
      defaultMessage:
        "Ostatnia płatność się nie powiodła. Prosimy o uregulowanie zaległych płatności.",
      id: "g4oURx",
    }),
    error: defineMessage({
      defaultMessage: "Coś poszło nie tak...",
      id: "U/QucF",
    }),
  },

  featureDiscrimination: {
    slotsLimitReached: defineMessage({
      defaultMessage: "Limit został osiągnięty. Wykup wyższy pakiet",
      id: "e4OCYo",
    }),
  },

  buttons: {
    buy: defineMessage({
      defaultMessage: "Wykup subskrypcję",
      id: "j1iKff",
    }),
    manage: defineMessage({ defaultMessage: "Zarządzaj", id: "cV6kLn" }),
    manageSubscription: defineMessage({
      defaultMessage: "Zarządzaj subskrypcją",
      id: "fp/a1T",
    }),
  },
};
