import {
  AppBar,
  Box,
  Container,
  SpeedDial,
  SpeedDialAction,
} from "@mui/material";
import { PropsWithChildren } from "react";

import { UserProvider } from "../../context/UserProvider";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { Header } from "../Header";
import { Navigation } from "../Navigation";
import { WithActiveSubscription } from "../WithActiveSubscription";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { helpCenterConfig } from "../../constants/help-center";
import { OnboardingDialog } from "../OnboardingDialog";

export function Layout({ children }: PropsWithChildren<{}>) {
  const openHelpCenter = () => {
    window.open(helpCenterConfig.MAIN_URL, "_blank");
  };

  return (
    <UserProvider>
      <Box sx={{ display: "flex" }}>
        <AppBar />
        <Navigation />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Container maxWidth="lg">
            <Header />
            <Box
              sx={{
                my: 4,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <WithActiveSubscription>{children}</WithActiveSubscription>
            </Box>
          </Container>
        </Box>
      </Box>
      <SpeedDial
        ariaLabel="Help"
        sx={{
          position: "fixed",
          bottom: 16,
          left: 16,
          ".MuiButtonBase-root": {
            backgroundColor: (theme) => theme.palette.grey[0],
            color: (theme) => theme.palette.text.primary,
          },
          ".MuiButtonBase-root:hover": {
            backgroundColor: (theme) => theme.palette.grey[100],
          },
        }}
        icon={<QuestionMarkIcon />}
      >
        <SpeedDialAction
          key={"help-center"}
          icon={<MenuBookIcon />}
          tooltipTitle={"Centrum pomocy"}
          onClick={openHelpCenter}
        />
      </SpeedDial>
      <OnboardingDialog />
    </UserProvider>
  );
}
