import Sentry from "@sentry/nextjs";

const GTAG_ID = process.env.NEXT_PUBLIC_GTAG_ID;

export const safeGtag = ((...args) => {
  if (typeof window === "undefined") {
    console.warn("gtag is not available on server side");
    return;
  }

  if (process.env.NODE_ENV === "development") {
    console.log(
      `[DEBUG] gtag(${args.map((arg) => JSON.stringify(arg)).join(", ")})`
    );
  }

  if (GTAG_ID) {
    window.gtag(...(args as Parameters<Gtag.Gtag>));
  } else if (process.env.VERCEL_END === "production") {
    Sentry.captureMessage(
      "process.env.NEXT_PUBLIC_GTAG_ID is not set on production environment"
    );
  }
}) as Gtag.Gtag;

export const sendEvent = (
  name: Gtag.EventNames | (string & {}),
  parameters?: Gtag.EventParams | Gtag.CustomParams
) => {
  safeGtag("event", name, parameters);
};

export const setUserId = (userId: string) => {
  safeGtag("config", GTAG_ID || "<GTAG_ID>", { user_id: userId });
};
