import { IconButton, Toolbar, Menu, MenuItem, Box } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import React from "react";
import { FormattedMessage } from "react-intl";

import { useUser } from "../../context/UserProvider";
import { UserAvatar } from "../UserAvatar";
import { useLogout } from "../../api/auth";
import { TrialBanner } from "../TrialBanner";
import { Link } from "../Link";
import { PaymentOverdueBanner } from "../PaymentOverdueBanner";

export function Header() {
  const { user, company } = useUser();
  const logout = useLogout();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout.mutate();
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: 1,
        }}
      >
        <Box sx={{ maxWidth: 800, justifySelf: "center" }}>
          {company?.subscriptionStatus === "trialing" && (
            <TrialBanner company={company} />
          )}
          {company?.subscriptionStatus === "past_due" && (
            <PaymentOverdueBanner />
          )}
        </Box>
        <Toolbar sx={{ justifyContent: "flex-end" }} disableGutters>
          {user && (
            <IconButton color="inherit" onClick={handleClick}>
              <UserAvatar fullName={user.name} />
            </IconButton>
          )}
        </Toolbar>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 30,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem component={Link} href="/account">
            <SettingsIcon sx={{ mr: 2 }} fontSize="small" />
            <FormattedMessage defaultMessage="Moje konto" id="ns1qhz" />
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <LogoutIcon sx={{ mr: 2 }} fontSize="small" />
            <FormattedMessage defaultMessage="Wyloguj się" id="K24Fuq" />
          </MenuItem>
        </Menu>
      </Box>
    </>
  );
}
