import { Alert } from "@mui/material";
import { useIntl } from "react-intl";
import { Company } from "../../types";
import { messages } from "../../locales/messages/subscription";
import { Link } from "../Link";

type TrialBannerProps = {
  company: Company;
};

export function TrialBanner({ company }: TrialBannerProps) {
  const intl = useIntl();

  return (
    <Alert severity="info" sx={{ width: "100%", alignSelf: "center", mr: 2 }}>
      {intl.formatMessage(messages.notif.trial)}&nbsp;
      <strong>
        {intl.formatDate(company.currentPeriodEnd, {
          year: "numeric",
          month: "long",
          day: "2-digit",
        })}
      </strong>
      .&nbsp;
      <Link href="/account" color="inherit">
        {intl.formatMessage(messages.buttons.buy)}
      </Link>
    </Alert>
  );
}
