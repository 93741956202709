import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { PropsWithChildren } from "react";
import { FormattedMessage } from "react-intl";

interface ConfirmationDialogProps {
  title: string;
  description?: string;
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

export function ConfirmationDialog({
  title,
  description,
  open,
  children,
  onConfirm,
  onClose,
}: PropsWithChildren<ConfirmationDialogProps>) {
  return (
    <>
      {children}
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        {description && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {description}
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={onClose} autoFocus>
            <FormattedMessage defaultMessage="Anuluj" id="JmR+Nv" />
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >
            <FormattedMessage defaultMessage="Potwierdź" id="gHvYLk" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
