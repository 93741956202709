import { Theme, Components } from "@mui/material/styles";

export const Autocomplete: Components<Theme> = {
  MuiAutocomplete: {
    styleOverrides: {
      paper: ({ theme }) => ({
        boxShadow: theme.customShadows.z20,
      }),
    },
  },
};

export default Autocomplete;
