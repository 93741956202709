import { Theme, Components } from "@mui/material/styles";

export const Paper: Components<Theme> = {
  MuiPaper: {
    defaultProps: {
      elevation: 0,
    },

    styleOverrides: {
      root: {
        backgroundImage: "none",
      },
    },
  },
};

export default Paper;
