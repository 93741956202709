import { Theme, Components } from "@mui/material/styles";

export const MenuItem: Components<Theme> = {
  MuiMenuItem: {
    styleOverrides: {
      root: {
        borderRadius: 8,
        padding: "6px 8px",
      },
    },
  },
};

export default MenuItem;
