import { useMutation, useQueryClient } from "react-query";
import { clientFetch, EmptyResponse } from ".";

import { Company, ErrorResponse, FormErrorResponse } from "../types";

export interface AdminItem {
  id: number;
  name: string;
  email: string;
  companies: Company[];
  featureDiscovery: AdminFeatureDiscovery;
}

export type Features =
  | "inviteUser"
  | "createWorkout"
  | "assignWorkouts"
  | "publishWorkouts"
  | "createExercise"
  | "createGroup"
  | "createTrainingPlan";

export type AdminFeatureDiscovery = {
  [key in Features]: boolean;
};

export interface AdminDetailsResponse {
  data: AdminItem;
}

type UpdateAdminBody = Pick<AdminItem, "name" | "email">;

export type UpdateAdminErrorResponse = FormErrorResponse<keyof UpdateAdminBody>;

export const updateAdmin = async (body: UpdateAdminBody) => {
  const response = await clientFetch<AdminDetailsResponse>("/admin/update", {
    method: "PATCH",
    body: JSON.stringify({ admin: body }),
  });

  return response;
};

export const useUpdateAdmin = () => {
  const queryClient = useQueryClient();

  return useMutation<
    AdminDetailsResponse,
    UpdateAdminErrorResponse,
    UpdateAdminBody
  >((body: UpdateAdminBody) => updateAdmin(body), {
    onSuccess: ({ data }) => {
      queryClient.setQueryData("me", data);
    },
  });
};

type UpdateAdminPasswordBody = {
  oldPassword: string;
  password: string;
  passwordConfirmation: string;
};

export type UpdateAdminPasswordErrorResponse = FormErrorResponse<
  keyof UpdateAdminPasswordBody
>;

export const updateAdminPassword = async (body: UpdateAdminPasswordBody) => {
  const response = await clientFetch<AdminDetailsResponse>(
    "/admin/change-password",
    {
      method: "PATCH",
      body: JSON.stringify({ admin: body }),
    }
  );

  return response;
};

export const useUpdateAdminPassword = () =>
  useMutation<
    AdminDetailsResponse,
    UpdateAdminPasswordErrorResponse,
    UpdateAdminPasswordBody
  >((body: UpdateAdminPasswordBody) => updateAdminPassword(body));

type RemindPasswordBody = {
  email: string;
};

export type RemindPasswordErrorResponse = FormErrorResponse<
  keyof EmptyResponse
>;

const remindPassword = async (body: RemindPasswordBody) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/remind-password`,
    {
      body: JSON.stringify(body),
      method: "PATCH",
      headers: {
        Accent: "pascal",
        "Content-Type": "application/json",
      },
    }
  );

  const data = await response.json();

  if (!response.ok) {
    throw { status: response.status, ...data };
  }

  return data;
};

export const useRemindPassword = () =>
  useMutation<EmptyResponse, RemindPasswordErrorResponse, RemindPasswordBody>(
    (body: RemindPasswordBody) => remindPassword(body)
  );

type SetPasswordBody = {
  password: string;
  passwordConfirmation: string;
};

export type SetPasswordErrorResponse = FormErrorResponse<keyof SetPasswordBody>;

const setPassword = async ({
  token,
  body,
}: {
  token: string;
  body: SetPasswordBody;
}) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/reset-password`,
    {
      body: JSON.stringify({ token, admin: body }),
      method: "PATCH",
      headers: {
        Accent: "pascal",
        "Content-Type": "application/json",
      },
    }
  );

  const data = await response.json();

  if (!response.ok) {
    throw { status: response.status, ...data };
  }

  return data;
};

export const useSetPassword = () => {
  return useMutation<
    EmptyResponse,
    SetPasswordErrorResponse,
    { token: string; body: SetPasswordBody }
  >(({ token, body }) => setPassword({ token, body }));
};

const discoverFeature = async (feature: Features) => {
  const response = await clientFetch<AdminDetailsResponse>(
    `/admin/discover-feature`,
    { method: "PATCH", body: JSON.stringify({ feature }) }
  );

  return response;
};

export const useDiscoverFeature = () => {
  const queryClient = useQueryClient();

  return useMutation<AdminDetailsResponse, ErrorResponse, Features>(
    (feature: Features) => discoverFeature(feature),
    {
      onSuccess: ({ data }) => {
        queryClient.setQueryData("me", data);
      },
    }
  );
};

type CompleteOnboardingBody = {
  referral: string | null;
  numberOfClients: string | null;
  trainerType: string | null;
  clientsType: string | null;
  inviteToMobileApp: boolean;
};

const completeOnboarding = async (body: CompleteOnboardingBody) => {
  const response = await clientFetch<AdminDetailsResponse>(
    `/complete-onboarding`,
    {
      method: "POST",
      body: JSON.stringify({ data: body, status: "completed" }),
    }
  );

  return response;
};

export const useCompleteOnboarding = () => {
  const queryClient = useQueryClient();

  return useMutation<
    AdminDetailsResponse,
    ErrorResponse,
    CompleteOnboardingBody
  >((body) => completeOnboarding(body), {
    onSuccess: ({ data }) => {
      queryClient.setQueryData("me", data);
    },
  });
};

const skipOnboarding = async () => {
  const response = await clientFetch<AdminDetailsResponse>(
    `/complete-onboarding`,
    {
      method: "POST",
      body: JSON.stringify({ status: "skipped", data: {} }),
    }
  );

  return response;
};

export const useSkipOnboarding = () => {
  const queryClient = useQueryClient();

  return useMutation<AdminDetailsResponse, ErrorResponse>(
    () => skipOnboarding(),
    {
      onSuccess: ({ data }) => {
        queryClient.setQueryData("me", data);
      },
    }
  );
};
