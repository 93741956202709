import { Components, Theme } from "@mui/material/styles";

export const Typography: Components<Theme> = {
  MuiTypography: {
    styleOverrides: {
      paragraph: ({ theme }) => ({
        marginBottom: theme.spacing(2),
      }),
      gutterBottom: ({ theme }) => ({
        marginBottom: theme.spacing(1),
      }),
    },
  },
};

export default Typography;
