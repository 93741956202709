import { Box } from "@mui/material";
import Avatar, { AvatarProps } from "@mui/material/Avatar";

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  /* eslint-enable no-bitwise */

  const angle = Math.round(hash % 360);

  return `hsl(${angle}, 100%, 40%)`;
}

type AvatarSize = "small" | "medium" | "large" | "x-large";

const sizeProps = (size: AvatarSize) => {
  return {
    small: {
      width: 28,
      height: 28,
      fontSize: "0.6rem",
    },

    medium: {
      width: 40,
      height: 40,
      fontSize: "1rem",
    },

    large: {
      width: 56,
      height: 56,
      fontSize: "1.2rem",
    },

    "x-large": {
      width: 112,
      height: 112,
      fontSize: "2.4rem",
    },
  }[size];
};

const initials = (fullName: string): string =>
  fullName
    .split(" ")
    .slice(0, 2)
    .map((name) => name[0])
    .join("");

function stringAvatar(fullName: string, size: AvatarSize) {
  return {
    sx: {
      bgcolor: stringToColor(fullName),
      ...sizeProps(size),
    },
    children: initials(fullName),
  };
}

interface UserAvatarProps extends AvatarProps {
  fullName: string | null;
  size?: AvatarSize;
  src?: string;
}

export function UserAvatar({
  size = "medium",
  src = "",
  fullName,
  ...props
}: UserAvatarProps) {
  if (!fullName) return <Avatar sx={sizeProps(size)} {...props} />;

  return src ? (
    <Avatar sx={sizeProps(size)} src={src} {...props}>
      {initials(fullName)}
    </Avatar>
  ) : (
    <Avatar {...stringAvatar(fullName, size)} {...props} />
  );
}
