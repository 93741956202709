import { useIntl } from "react-intl";
import { Chip } from "../Chip";

export function NewChip() {
  const intl = useIntl();

  return (
    <Chip
      label={intl.formatMessage({ defaultMessage: "Nowość", id: "irw3EP" })}
      color="success"
    />
  );
}
