import {
  Box,
  Button,
  Dialog,
  FormHelperText,
  Grid,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TabPanel } from "../TabPanel";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Image from "next/image";
import CloseIcon from "@mui/icons-material/Close";
import { useUser } from "../../context/UserProvider";
import { useCompleteOnboarding, useSkipOnboarding } from "../../api/admin";
import { useSnackbar } from "notistack";
import { FormattedMessage, useIntl } from "react-intl";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

interface Form {
  referral: string | null;
  numberOfClients: string | null;
  trainerType: string | null;
  clientsType: string | null;
  customTrainerType: string | null;
  inviteToMobileApp: boolean;
}

const defaultValues: Form = {
  referral: null,
  numberOfClients: null,
  trainerType: null,
  customTrainerType: null,
  clientsType: null,
  inviteToMobileApp: true,
};

export function OnboardingDialog() {
  const ONBOARDING_STEPS = 5;

  const { company } = useUser();

  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (company && company.onboardingStatus === "pending") {
      setOpen(true);
    }
  }, [company]);

  const skipOnboarding = useSkipOnboarding();

  const completeOnboarding = useCompleteOnboarding();

  const actionButtonsDisabled =
    skipOnboarding.isLoading || completeOnboarding.isLoading;

  const nextStep = () => setStep(step + 1);

  const previousStep = () => setStep(step - 1);

  const handleClose = () => {
    skipOnboarding.mutate(undefined, {
      onSuccess: () => setOpen(false),
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            defaultMessage: "Coś poszło nie tak...",
            id: "U/QucF",
          }),
          { variant: "error" }
        );
      },
    });
  };

  const onSubmit: SubmitHandler<Form> = (data) => {
    const customTrainerType = getValues("customTrainerType");

    const formData = isSelected("trainerType", "Inne")
      ? { ...data, trainerType: customTrainerType || "Inne" }
      : data;

    completeOnboarding.mutate(formData, {
      onSuccess: () => {
        setStep(ONBOARDING_STEPS + 2);
      },
      onError: () => {
        enqueueSnackbar(
          intl.formatMessage({
            defaultMessage: "Coś poszło nie tak...",
            id: "U/QucF",
          }),
          { variant: "error" }
        );
      },
    });
  };

  const shouldShowBackArrow = (step: number) =>
    step > 2 && step <= ONBOARDING_STEPS + 1;

  const progress = (step: number) => ((step - 1) / ONBOARDING_STEPS) * 100;

  const isOnboardingStep = (step: number) =>
    step != 1 && step != ONBOARDING_STEPS + 2;

  const intl = useIntl();

  const form = useForm<Form>({ defaultValues });

  const { control, watch, setValue, getValues, handleSubmit } = form;

  watch();

  const setOption = (fieldName: keyof Form, value: string | boolean) => {
    setValue(fieldName, value);
  };

  const isSelected = (fieldName: keyof Form, value: string | boolean) =>
    getValues(fieldName) === value;

  const Option = ({
    label,
    fieldName,
    value,
  }: {
    label: string;
    fieldName: keyof Form;
    value: string | boolean;
  }) => {
    const color = isSelected(fieldName, value) ? "primary" : "inherit";

    return (
      <Button
        onClick={() => {
          setOption(fieldName, value);
        }}
        color={color}
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
      >
        {label}
      </Button>
    );
  };

  return (
    // https://mui.com/material-ui/customization/z-index/
    // We want to have it higher than tooltip to hide feature tooltips before
    // onboarding has been completed
    <Dialog sx={{ zIndex: 1600 }} fullScreen open={open} onClose={handleClose}>
      <Box
        sx={{
          m: 4,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Image src="/logo.png" alt="logo" width="56" height="56" />

        <IconButton
          onClick={handleClose}
          size="large"
          disabled={actionButtonsDisabled}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexFlow: "column",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
        }}
      >
        <Box sx={{ width: "450px", minHeight: "400px" }}>
          {isOnboardingStep(step) ? (
            <Grid spacing={1} container sx={{ mb: 3 }}>
              <BorderLinearProgress
                variant="determinate"
                value={progress(step)}
                sx={{ flexGrow: 1 }}
              />
            </Grid>
          ) : null}
          <TabPanel value={step} index={1}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexFlow: "column",
              }}
            >
              <Typography variant="h3" gutterBottom>
                <FormattedMessage
                  defaultMessage="Witamy w Coach Mate 👋"
                  id="EcgPYH"
                />
              </Typography>

              <Box>
                <Typography color="text.secondary">
                  <FormattedMessage
                    defaultMessage="Zadamy Ci kilka pytań które pozwolą nam dopasować aplikację
                    do Twoich potrzeb. To zajmie tylko chwilę."
                    id="lKtisg"
                  />
                </Typography>
              </Box>

              <Box sx={{ mt: 3, width: "100%" }}>
                <Button
                  fullWidth
                  onClick={nextStep}
                  variant="contained"
                  size="large"
                >
                  <FormattedMessage defaultMessage="Zaczynamy" id="cgSPtC" />
                </Button>
              </Box>
            </Box>
          </TabPanel>

          <TabPanel value={step} index={2}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h4">
                <FormattedMessage
                  defaultMessage="Skąd znasz Coach Mate?"
                  id="tEqjO9"
                />
              </Typography>
            </Box>

            <Box sx={{ mb: 2 }}>
              <Controller
                name="referral"
                control={control}
                render={({ field: { onChange } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Polecenie, facebook, instagram itp.",
                      id: "U2qt7d",
                    })}
                  ></TextField>
                )}
              />
            </Box>

            <Box onClick={nextStep} sx={{ mb: 1, mt: 3, width: "100%" }}>
              <Button variant="contained" fullWidth size="large">
                <FormattedMessage defaultMessage="Dalej" id="+N4HKT" />
              </Button>
            </Box>
          </TabPanel>

          <TabPanel value={step} index={3}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h4">
                <FormattedMessage
                  defaultMessage="Z iloma trenującymi współpracujesz?"
                  id="lKh6la"
                />
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexFlow: "column",
              }}
            >
              <Option label="0" fieldName="numberOfClients" value="0" />

              <Option label="1-10" fieldName="numberOfClients" value="1-10" />

              <Option label="11-25" fieldName="numberOfClients" value="11-25" />

              <Option label="25+" fieldName="numberOfClients" value="20+" />

              <Box onClick={nextStep} sx={{ mb: 1, mt: 1, width: "100%" }}>
                <Button variant="contained" fullWidth size="large">
                  <FormattedMessage defaultMessage="Dalej" id="+N4HKT" />
                </Button>
              </Box>
            </Box>
          </TabPanel>

          <TabPanel value={step} index={4}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h4">
                <FormattedMessage
                  defaultMessage="Czym się zajmujesz?"
                  id="bsrX3p"
                />
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexFlow: "column",
              }}
            >
              <Option
                label={intl.formatMessage({
                  defaultMessage: "Treningami personalnymi",
                  id: "Gy0LnM",
                })}
                fieldName="trainerType"
                value="Trener personalny"
              />

              <Option
                label={intl.formatMessage({
                  defaultMessage: "Treningami przygotowania motorycznego",
                  id: "vF0QQz",
                })}
                fieldName="trainerType"
                value="Trener przygotowania motorycznego"
              />

              <Option
                label={intl.formatMessage({
                  defaultMessage: "Fizjoterapią",
                  id: "2at9G9",
                })}
                fieldName="trainerType"
                value="Fizjoterapeuta"
              />

              <Option
                label={intl.formatMessage({
                  defaultMessage: "Czymś innym",
                  id: "D0B2Ma",
                })}
                fieldName="trainerType"
                value="Inne"
              />

              {isSelected("trainerType", "Inne") ? (
                <Controller
                  name="customTrainerType"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <Box sx={{ mb: 2, width: "100%" }}>
                      <TextField
                        fullWidth
                        onChange={onChange}
                        placeholder={intl.formatMessage({
                          defaultMessage: "Powiedz nam więcej!",
                          id: "v+6Vtc",
                        })}
                      ></TextField>
                    </Box>
                  )}
                />
              ) : null}

              <Box onClick={nextStep} sx={{ mb: 1, mt: 1, width: "100%" }}>
                <Button variant="contained" size="large" fullWidth>
                  <FormattedMessage defaultMessage="Dalej" id="+N4HKT" />
                </Button>
              </Box>
            </Box>
          </TabPanel>

          <TabPanel value={step} index={5}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h4">
                <FormattedMessage
                  defaultMessage="Najczęściej współpracujesz z..."
                  id="41PHps"
                />
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexFlow: "column",
              }}
            >
              <Option
                label={intl.formatMessage({
                  defaultMessage: "Każdym",
                  id: "x0JHzX",
                })}
                fieldName="clientsType"
                value="Wszyscy"
              />

              <Option
                label={intl.formatMessage({
                  defaultMessage: "Piłkarzami",
                  id: "ZRSbGL",
                })}
                fieldName="clientsType"
                value="Piłkarze"
              />

              <Option
                label={intl.formatMessage({
                  defaultMessage: "Innymi sportowcami",
                  id: "zbzMG5",
                })}
                fieldName="clientsType"
                value="Sportowcy"
              />
            </Box>

            <Box onClick={nextStep} sx={{ mb: 1, mt: 1, width: "100%" }}>
              <Button variant="contained" size="large" fullWidth>
                <FormattedMessage defaultMessage="Dalej" id="+N4HKT" />
              </Button>
            </Box>
          </TabPanel>

          <TabPanel value={step} index={6}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h4">
                <FormattedMessage
                  defaultMessage="Czy chcesz otrzymać zaproszenie do aplikacji mobilnej dla
                  trenujących?"
                  id="L/YTQ1"
                />
              </Typography>
            </Box>

            <Option
              label="Tak, poproszę"
              fieldName="inviteToMobileApp"
              value={true}
            />

            <Option
              label="Nie, dzięki"
              fieldName="inviteToMobileApp"
              value={false}
            />

            <FormHelperText>
              <FormattedMessage
                defaultMessage="Dedykowana aplikacja mobilna pozwala trenującemu na przeprowadzenie interaktywnego treningu."
                id="VLbE9P"
              />
            </FormHelperText>

            <Box sx={{ mb: 1, mt: 3, width: "100%" }}>
              <Button
                disabled={actionButtonsDisabled}
                variant="contained"
                size="large"
                onClick={handleSubmit(onSubmit)}
                fullWidth
              >
                <FormattedMessage defaultMessage="Zakończ" id="v0lTFF" />
              </Button>
            </Box>
          </TabPanel>

          <TabPanel value={step} index={7}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexFlow: "column",
              }}
            >
              <Typography variant="h3" gutterBottom>
                <FormattedMessage
                  defaultMessage="To wszystko! 🥳"
                  id="hYi+O7"
                />
              </Typography>

              <Typography color="text.secondary">
                <FormattedMessage
                  defaultMessage="Dziękujemy za poświęcony czas i zapraszamy do zapoznania się z możliwościomi naszej platformy."
                  id="PGDgNF"
                />
              </Typography>

              <Box sx={{ mt: 3, width: "100%" }}>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  onClick={() => setOpen(false)}
                >
                  <FormattedMessage
                    defaultMessage="Przejdź do aplikacji"
                    id="r/7ABC"
                  />
                </Button>
              </Box>
            </Box>
          </TabPanel>

          {shouldShowBackArrow(step) ? (
            <Button
              onClick={previousStep}
              color="inherit"
              startIcon={<ArrowBackIcon />}
            >
              <FormattedMessage defaultMessage="Wstecz" id="xX+nrs" />
            </Button>
          ) : null}
        </Box>
      </Box>
    </Dialog>
  );
}

export default OnboardingDialog;
