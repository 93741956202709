import { createContext, PropsWithChildren, useContext, useEffect } from "react";
import { AdminFeatureDiscovery } from "../../api/admin";
import * as Sentry from "@sentry/nextjs";

import { useLogout, useSession } from "../../api/auth";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { Company } from "../../types";
import { setUserId } from "../../utils/analytics";

export interface User {
  id: number;
  name: string;
  email: string;
  companies: Company[];
  featureDiscovery: AdminFeatureDiscovery;
}

interface UserContext {
  user: User | undefined;
  company: Company | undefined;
}

const UserContext = createContext<UserContext>(undefined!);

export function useUser() {
  const user = useContext(UserContext);

  return user;
}

export function UserProvider({ children }: PropsWithChildren<{}>) {
  const [companyId, setCompanyId] = useLocalStorage<number | null>(
    "companyId",
    null
  );
  const login = useSession(false);
  const logout = useLogout();

  useEffect(() => {
    if (!login.data && login.isIdle) {
      login.refetch();
    }
  }, [login]);

  useEffect(() => {
    if (login.isFetched && !login.data) {
      logout.mutate();
    }
  }, [login.isFetched, login.data, logout.mutate]);

  useEffect(() => {
    if (login.data) {
      setCompanyId(login.data.companies[0].id);
      Sentry.setUser({
        id: String(login.data.id),
        email: login.data.email,
        username: login.data.name,
      });
      setUserId(String(login.data.id));
    }
  }, [login.data, companyId]);

  return (
    <UserContext.Provider
      value={{
        user: login.data,
        company: login.data?.companies.find(({ id }) => id === companyId),
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
