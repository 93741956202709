import pl from "../locales/compiled-lang/pl.json";
import en from "../locales/compiled-lang/en.json";

export function loadLocaleData(locale: string) {
  switch (locale) {
    case "pl":
      return pl;
    default:
      return en;
  }
}
