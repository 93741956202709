import { alpha, Box } from "@mui/material";
import { ChipProps } from "./types";

export function Chip({ color = "info", cursor = "inherit", label }: ChipProps) {
  return (
    <Box
      sx={{
        height: "22px",
        minWidth: "22px",
        borderRadius: "6px",
        backgroundColor: (theme) => alpha(theme.palette[color].main, 0.12),
        color: `${color}.dark`,
        fontWeight: 700,
        fontSize: "0.75rem",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 8px",
        cursor,
      }}
    >
      {label}
    </Box>
  );
}
