export const helpCenterConfig = {
  MAIN_URL:
    "https://help-coachmate.notion.site/Centrum-pomocy-7d57a95667cc423aa908076caad447f4",
  INVITE_USER_ARTICLE_URL:
    "https://help-coachmate.notion.site/Zaproszenie-u-ytkownika-07a437ae0d7945da98deb3fbc12d0e4a",
  CREATE_WORKOUT_ARTICLE_URL:
    "https://help-coachmate.notion.site/Tworzenie-treningu-710cadfb6a3b40aaa4366491dba715b4",
  ASSIGN_WORKOUTS_ARTICLE_URL:
    "https://help-coachmate.notion.site/Przypisywanie-treningu-94290e4354094b058d5b8ccb6b7fb224",
  CREATE_TRAINING_PLAN_ARTICLE_URL:
    "https://help-coachmate.notion.site/Programy-treningowe-f12b629e4dac4c5f9be598eef3829538",
};
