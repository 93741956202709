const baseUrl = process.env.NEXT_PUBLIC_API_URL;

let token: string;

export type Params = {
  [key: string]: any;
};

export type EmptyResponse = {};

export const clientFetch = async <ResponseData>(
  input: string,
  { params, ...init }: RequestInit & { params?: Params } = {}
) => {
  if (!token) {
    const response = await fetch("/api/login");
    token = response.headers.get("access-token")!;
  }
  let companyId: string | null;

  try {
    companyId = localStorage.getItem("companyId");
  } catch (e) {
    throw e;
  }

  if (!companyId) {
    throw Error("companyId not specified");
  }

  const url = new URL(`${baseUrl}/c/${companyId}${input}`);

  if (params) {
    Object.entries(params).forEach(([key, value]) =>
      url.searchParams.append(key, value)
    );
  }

  const response = await fetch(url.toString(), {
    ...init,
    headers: {
      ...init.headers,
      ...(token && { Authorization: `Bearer ${token}` }),
      Accent: "pascal",
      "Content-Type": "application/json",
    },
  });

  const data: ResponseData = await response.json();

  if (response.status === 401) {
    if (token) {
      // clear cookie on api/login
    }
    // notify about session ending
  }

  if (!response.ok) {
    throw { status: response.status, ...data };
  }

  return data;
};
