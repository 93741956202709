import {
  Toolbar,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Box,
} from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import ArticleIcon from "@mui/icons-material/Article";
import KeyIcon from "@mui/icons-material/Key";
import PersonIcon from "@mui/icons-material/Person";
import { PropsWithChildren } from "react";
import { LinkProps } from "next/link";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import { useRouter } from "next/router";
import Image from "next/image";
import { Link } from "../Link";
import { NewChip } from "../NewChip";

const A = styled.a`
  text-decoration: none;
  color: initial;
`;

const selected = (path: string, href: string) => {
  return path.startsWith(href);
};

const WrapperLink = ({
  children,
  newFeatures = false,
  ...props
}: PropsWithChildren<LinkProps & { newFeatures?: boolean }>) => {
  const { asPath } = useRouter();

  return (
    <Link {...props} underline="none" passHref>
      <A>
        <ListItemButton
          sx={{
            borderRadius: "8px",
            px: "16px",
            m: "8px",
            minWidth: "310px",
            "&.Mui-selected .MuiListItemIcon-root": {
              color: "primary.main",
            },
            "&.Mui-selected .MuiListItemText-root": {
              color: "primary.main",
            },
            "&.Mui-selected .MuiListItemText-root .MuiListItemText-primary": {
              fontWeight: 600,
            },
          }}
          selected={selected(asPath, props.href as string)}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {children}
            {newFeatures && (
              <Box sx={{ ml: 1 }}>
                <NewChip />
              </Box>
            )}
          </Box>
        </ListItemButton>
      </A>
    </Link>
  );
};

export function Navigation() {
  const intl = useIntl();

  return (
    <div>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          m: "16px",
        }}
      >
        <Box>
          <Link href="/users" passHref>
            <Image src="/logo.png" alt="logo" width="56" height="56" />
          </Link>
        </Box>
      </Toolbar>
      <List>
        <WrapperLink href="/users">
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText
            primary={intl.formatMessage({
              defaultMessage: "Trenujący",
              id: "s6+Nnv",
            })}
          />
        </WrapperLink>

        <WrapperLink href="/groups">
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText
            primary={intl.formatMessage({
              defaultMessage: "Grupy",
              id: "nPXLbv",
            })}
          />
        </WrapperLink>

        <WrapperLink href="/exercises">
          <ListItemIcon>
            <FitnessCenterIcon />
          </ListItemIcon>
          <ListItemText
            primary={intl.formatMessage({
              defaultMessage: "Ćwiczenia",
              id: "fNCgPd",
            })}
          />
        </WrapperLink>

        <WrapperLink href="/workout-templates">
          <ListItemIcon>
            <ArticleIcon />
          </ListItemIcon>
          <ListItemText
            primary={intl.formatMessage({
              defaultMessage: "Szablony treningowe",
              id: "rlfJR/",
            })}
          />
        </WrapperLink>

        <WrapperLink href="/workout-sets">
          <ListItemIcon>
            <ArticleIcon />
          </ListItemIcon>
          <ListItemText
            primary={intl.formatMessage({
              defaultMessage: "Programy treningowe",
              id: "ACdSuf",
            })}
          />
        </WrapperLink>

        <WrapperLink href="/invites">
          <ListItemIcon>
            <KeyIcon />
          </ListItemIcon>
          <ListItemText
            primary={intl.formatMessage({
              defaultMessage: "Zaproszenia",
              id: "i2DptW",
            })}
          />
        </WrapperLink>
      </List>
    </div>
  );
}
