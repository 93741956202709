import Card from "./Card";
import Lists from "./Lists";
import Paper from "./Paper";
import Input from "./Input";
import Button from "./Button";
import Tooltip from "./Tooltip";
import Typography from "./Typography";
import IconButton from "./IconButton";
import Autocomplete from "./Autocomplete";
import MenuItem from "./MenuItem";

export default function ComponentsOverrides() {
  return {
    ...Card,
    ...Lists,
    ...Paper,
    ...Input,
    ...Button,
    ...Tooltip,
    ...Typography,
    ...IconButton,
    ...Autocomplete,
    ...MenuItem,
  };
}
