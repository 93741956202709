import { createContext, PropsWithChildren, useContext, useState } from "react";

import { ConfirmationDialog } from "../../components/ConfirmationDialog";

interface DialogProps {
  title: string;
  description?: string;
  onConfirm: () => void;
}
interface ConfirmationDialogContext {
  openConfirmationDialog: (dialogProps: DialogProps) => void;
}

const ConfirmationDialogContext = createContext<ConfirmationDialogContext>(
  undefined!
);

export function useConfirmationDialog() {
  const user = useContext(ConfirmationDialogContext);

  return user;
}

export function ConfirmationDialogProvider({
  children,
}: PropsWithChildren<{}>) {
  const [open, setOpen] = useState(false);
  const [dialogProps, setDialogProps] = useState<DialogProps>();

  const openConfirmationDialog = (dialogProps: DialogProps) => {
    setDialogProps(dialogProps);
    setOpen(true);
  };

  return (
    <ConfirmationDialog
      open={open}
      onClose={() => setOpen(false)}
      {...dialogProps!}
    >
      <ConfirmationDialogContext.Provider
        value={{
          openConfirmationDialog,
        }}
      >
        {children}
      </ConfirmationDialogContext.Provider>
    </ConfirmationDialog>
  );
}
