import Head from "next/head";
import { AppProps } from "next/app";
import { CacheProvider, EmotionCache } from "@emotion/react";
import { NextPage } from "next/types";
import { QueryClient, QueryClientProvider } from "react-query";
import { IntlProvider } from "react-intl";
import { PropsWithChildren } from "react";
import { SnackbarProvider } from "notistack";

import { createEmotionCache } from "../config/createEmotionCache";
import { Layout } from "../components/Layout";
import { loadLocaleData } from "../config/i18n";
import { ConfirmationDialogProvider } from "../context/ConfirmationDialogProvider";
import ThemeConfig from "../theme";
import GlobalStyles from "../theme/global-styles";
import Script from "next/script";

const GTAG_ID = process.env.NEXT_PUBLIC_GTAG_ID;

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();
const queryClient = new QueryClient();

type NextPageWithLayout = NextPage & {
  noLayout?: boolean;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

interface MyAppProps extends AppPropsWithLayout {
  emotionCache?: EmotionCache;
}

export default function MyApp({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
  router,
}: MyAppProps) {
  const locale = router.locale!;
  const messages = loadLocaleData(locale);
  const ComponentWrapper = Component.noLayout
    ? ({ children }: PropsWithChildren<{}>) => <>{children}</>
    : Layout;

  return (
    <>
      {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${GTAG_ID}`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GTAG_ID}', {'debug_mode':${
          process.env.NEXT_PUBLIC_VERCEL_ENV !== "production"
        }});
            `}
      </Script>
      <CacheProvider value={emotionCache}>
        <Head>
          <meta name="viewport" content="viewport-fit=cover, width=1200" />
          <meta name="theme-color" content="#F9FAFB" />
        </Head>
        <IntlProvider locale={locale} messages={messages}>
          <ThemeConfig>
            <SnackbarProvider
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
              <QueryClientProvider client={queryClient}>
                <ConfirmationDialogProvider>
                  <GlobalStyles />
                  <ComponentWrapper>
                    <Component {...pageProps} />
                  </ComponentWrapper>
                </ConfirmationDialogProvider>
              </QueryClientProvider>
            </SnackbarProvider>
          </ThemeConfig>
        </IntlProvider>
      </CacheProvider>
    </>
  );
}
