import { Theme, Components } from "@mui/material/styles";

export const Tooltip: Components<Theme> = {
  MuiTooltip: {
    styleOverrides: {
      tooltip: ({ theme }) => ({
        backgroundColor: theme.palette.grey[800],
      }),
      arrow: ({ theme }) => ({
        color: theme.palette.grey[800],
      }),
    },
  },
};

export default Tooltip;
