import { Box, Typography, Button } from "@mui/material";
import { useRouter } from "next/router";
import { PropsWithChildren } from "react";
import { useIntl } from "react-intl";
import { useUser } from "../../context/UserProvider";
import { Link } from "../Link";
import { messages } from "../../locales/messages/subscription";

export function WithActiveSubscription({ children }: PropsWithChildren<{}>) {
  const router = useRouter();

  const intl = useIntl();

  const { company } = useUser();

  if (!company || company.active || router.pathname === "/account") {
    return <>{children}</>;
  } else {
    return (
      <Box
        sx={{
          marginTop: 24,
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
          alignItems: "center",
        }}
      >
        <Typography sx={{ mt: 3 }} component="h1" variant="h5">
          {intl.formatMessage(messages.notif.inactive)}
        </Typography>

        <Link href="/account" underline="none">
          <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            {intl.formatMessage(messages.buttons.buy)}
          </Button>
        </Link>
      </Box>
    );
  }
}
